import React from "react"
import PhoneInput from "react-phone-input-2"

import IhLogo from "../images/ihlogo.svg"
import authTop from "../images/authTop.svg"
import tickCircle from "../images/tick-circle.svg"
import emptyTickCircle from "../images/empty-tick-circle.svg"
import checkbox from "../images/checkbox.svg"
import eyeToggleOff from "../images/eyeToggleOff.svg"
import eyeToggleOn from "../images/eyeToggleOn.svg"

import "react-phone-input-2/lib/style.css"
import "./signup.css"
import Popup from "./common/Popup";
import { navigate } from 'gatsby'

import { logEvent } from '../core/analytics';
import { useMixpanel } from 'gatsby-plugin-mixpanel'


import { updowncheck, strongRegex } from './common/utils';


function SignUp({
  domainInfo,
  createEmployeeHandle,
  checkOrgEmail,
  countryPhoneCode,
  countryCode,
  isEmailExist,
  isResentEmail,
  isEmailVerified,
  successfullyCreated,
  resendVerificationEmailHandle
}) {
  const { email_extension, logo, organisation_name } = domainInfo

  const [data, setData] = React.useState({});
  const [code, setCode] = React.useState(countryPhoneCode);
  const [isDisabled, setIsDisabled] = React.useState(true);
  const [isPasswordStrong1, setPasswordStrong1] = React.useState(false);
  const [isPasswordStrong2, setPasswordStrong2] = React.useState(false);

  const [passDoneMatch, setPassDontMatch] = React.useState(false);


  const [firstName, setFirstName] = React.useState(false);
  const [lastName, setLastName] = React.useState(false);
  const [emailError, setEmailError] = React.useState(false);
  const [passwordError, setPasswordError] = React.useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = React.useState(false);
  const [acceptTnc, setAcceptTnc] = React.useState(false);
  const [mobileError, setMobileError] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false)
  const [showResendVerification, setShowResendVerification] = React.useState(false);
  const [resendMail, setResendMail] = React.useState("")
  const [showResendSuccessfull, setShowResendSuccessfull] = React.useState(false)
  const [showResendFailed, setShowResendFailed] = React.useState(false);

  const mixpanel = useMixpanel()

  React.useEffect(() => {
    canClickOnSubmitBtn()
  }, [isEmailExist, isEmailVerified])

  const createEmployeeHandleFunc = () => {

    if('first_name' in data && data['first_name'] != "") {
      setFirstName(false);
    } else {
      setFirstName(true);
    }

    if('last_name' in data && data['last_name'] != "") {
      setLastName(false);
    } else {
      setLastName(true);
    }

    if('email' in data && data['email'] != "") {
      setEmailError(false);
    } else {
      setEmailError(true);
    }

    if('password' in data && data['password'] != "") {
      setPasswordError(false);
    } else {
      setPasswordError(true);
    }

    if('confirmpassword' in data && data['confirmpassword'] != "") {
      setConfirmPasswordError(false);
    } else {
      setConfirmPasswordError(true);
    }

    logEvent("b2b_landing_page_click_on_signup", data, mixpanel)

    if(firstName == false && emailError == false && passwordError == false && confirmPasswordError == false) {
      createEmployeeHandle(data);
    }

  }

  const onBlurHandle = event => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      checkOrgEmail(data["email"])
    }
  }


  const setEmailField = value => {
    if(value == "" || value == null) {
      setEmailError(true);
    }
    const val = value.match(/^(.+)@/);
    if(val == null) {
      updateFormData("email", value)
      setEmailError(false);
    } else {
      updateFormData("email", val[1])
      setEmailError(false);
    }
  }

  const updateFirstNameHandle = value => {
    if (value != "") {
      updateFormData("first_name", value)
      setFirstName(false)
    } else {
      updateFormData("first_name", value)
      setFirstName(true)
    }
  }

  const updateLastNameHandle = value => {
    if (value != "") {
      updateFormData("last_name", value)
      setLastName(false)
    } else {
      updateFormData("last_name", value)
      setLastName(true)
    }
  }


  const updateFormData = (field, value) => {
    setData({
      ...data,
      [field]: value,
    })
  }

  React.useEffect(() => {
    canClickOnSubmitBtn()
  }, [data, acceptTnc, isPasswordStrong1, isPasswordStrong2, mobileError])

  const passCheck = (key, value) => {
    if(updowncheck.test(value)) {
      setPasswordStrong1(true)
    } else {
      setPasswordStrong1(false)
    }
    if(strongRegex.test(value)) {
      setPasswordStrong2(true)
    } else {
      setPasswordStrong2(false)
    }
    updateFormData(key, value)
  }

  const confirmPassCheck = (key, value) => {
    if(data['password'] != value) {
      setPassDontMatch(true)
    } else {
      setPassDontMatch(false)
    }
    updateFormData(key, value)
  }

  const checkAllField = () => {
    if('first_name' in  data && data['first_name'] != "" && 'last_name' in data && data['last_name'] != "" && 'email' in data && data['email'] != "" && 'password' in data && data['password'] != "" && 'confirmpassword' in data && data['confirmpassword'] != "") {
      return false;
    }
    return true;
  }

  const canClickOnSubmitBtn  = () => {
    if(isEmailExist || isEmailVerified || acceptTnc === false || isPasswordStrong1 == false || isPasswordStrong2 == false || mobileError)  {
      return setIsDisabled(true)
    } else if(data['password'] != data['confirmpassword']) {
      return setIsDisabled(true);
    } else if(checkAllField()) {
      return setIsDisabled(true);
    } else {

      if('first_name' in data && data['first_name'] != "") {
        setIsDisabled(false)
      } else {
        setIsDisabled(true)
      }

      if('last_name' in data && data['last_name'] != "") {
        setIsDisabled(false)
      } else {
        setIsDisabled(true)
      }

      if('email' in data && data['email'] != "") {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }

      if('password' in data && data['password'] != "") {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }

      if('confirmpassword' in data && data['confirmpassword'] != "") {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }

    }
  }

  const openEmailClient = () => {
    // window.open('mailto:')
    navigate('/download');
  }

  const navigateToDownload = () => {
    navigate('/download');
    return true
  }

  const resendVerificationEmail = () => {
    resendVerificationEmailHandle(`${data['email']}${email_extension}`)
  }

  const updateMobileNumber = value => {
    if(value.length < 10 || value.length > 10) {
      setMobileError(true)
    } else {
      updateFormData("mobile", value)
      setMobileError(false)
    }
  }

  const resendEmail = () => {
    const payload = {
      email: `${resendMail}${email_extension}`,
    }
    fetch(`${process.env.BACKEND_API_URL}/b2b/email/resend`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify(payload)
    })
      .then((res) => {
        console.log("mail is ", payload);
        console.log("sucess", res);
        res.json().then(data => {
          console.log("the res data is ", data);
          if (data.message === "not_found") {
            setShowResendVerification(false);
            setShowResendFailed(true);
          } else {
            setShowResendVerification(false);
            setShowResendSuccessfull(true);
          }
        })
      })
      .catch((err) => console.log("Error in resending: ", err))
  }

  return (
    <React.Fragment>
      {
        showResendVerification && <Popup
          title="Resend Verification Mail"
          description="You will receive another verification e-mail if your e-mail address has been registered with Amaha."
          buttonLabel="RESEND MAIL"
          popupType="success"
          showEmailInput={true}
          companyDomain={email_extension}
          handleClick={() => resendEmail()}
          togglePopup={() => navigateToDownload()}
          setMail={(mail) => setResendMail(mail)}
        />
      }
      {
        showResendSuccessfull && <Popup
          title="Success"
          description="To finish setting up your account, all you need to do is click on the verification button below!"
          buttonLabel="VERIFY EMAIL NOW"
          popupType="success"
          showEmailInput={false}
          togglePopup={() => navigateToDownload()}
        />
      }
      {
        showResendFailed && <Popup
          title="E-mail ID registration failed"
          description="Please register again using the option below."
          buttonLabel="CREATE ACCOUNT"
          popupType="error"
          showEmailInput={false}
          handleClick={() => {
            setShowResendFailed(false)
            navigate('/')
          }}
          togglePopup={() => navigateToDownload()}
        />
      }
      {successfullyCreated && <Popup
        title="Account Created!"
        description="To finish signing up, you can verify your email ID through your work email."
        buttonLabel="VERIFY EMAIL ID"
        popupType="success"
        showEmailInput={false}
        handleClick={()=> openEmailClient()}
        togglePopup={()=> navigateToDownload()}
      />}
      <div
        className="container-fluid body-image vh-100"
        style={{ height: "100%" }}
      >
        <div className="row justify-content-center h-100">
          <div className="col-sm-12 d-sm-block d-md-none px-0">
            <img src={authTop} alt="" className="auth-top-image-mobile" />
          </div>
          <div className="col-sm-12 col-md-8 col-lg-6 px-0 px-md-2">
            <div className="bg-white">
              <div
                className="d-flex justify-content-center mb-3 align-items-center company-logos-group"
                style={{ paddingTop: "2vh" }}
              >
                <div className="text-right px-1  px-sm-none">
                  <img
                    src={logo}
                    alt={organisation_name}
                    style={{ width: "110px" }}
                  />
                </div>
                <p className="mx-2">with</p>
                <div className="text-left px-1  px-sm-none">
                  <img src={IhLogo} alt="Amaha" />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <br />
                  <div id="errors" />
                  <div id="create-user">
                    <div className="text-center">
                      <h4 className="signup-main-text">
                        Welcome to Amaha's Happiness Programme
                      </h4>
                      <p className="sub">
                        Please create an account using your official work email ID to begin your well-being journey
                      </p>
                    </div>
                    <fieldset className="signup-fieldset">
                    <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          name="firstname"
                          id="firstname"
                          placeholder="First Name"
                          role="presentation"
                          autoComplete="nope"
                          onChange={event =>
                            updateFirstNameHandle(event.target.value)
                          }
                        />
                        {firstName && <div id="firstname_validate">
                          <label id="firstname-error" className="error" htmlFor="firstname">
                            <p>Please enter first name</p>
                          </label>
                        </div>}
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          name="lastname"
                          id="lastname"
                          placeholder="Last Name"
                          role="presentation"
                          autoComplete="nope"
                          onChange={event =>
                            updateLastNameHandle(event.target.value)
                          }
                        />
                        {lastName && <div id="lastname_validate">
                          <label id="lastname-error" className="error" htmlFor="lastname">
                            <p>Please enter last name</p>
                          </label>
                        </div>}

                      </div>
                      <div className="form-group">
                        <div className="input-group mb-2 mr-sm-2">
                          <input
                            name="email"
                            type="text"
                            className="form-control no-right-radius"
                            id="email"
                            value={data['email']}
                            placeholder="Work Email ID"
                            role="presentation"
                            autoComplete="nope"
                            onChange={event =>
                              setEmailField(event.target.value)
                            }
                            onBlur={event => onBlurHandle(event)}
                          />
                          <div className="input-group-append">
                            <div
                              className="input-group-text"
                              style={{
                                borderRadius: "0 12px 12px 0 !important",
                              }}
                            >
                              {email_extension}
                            </div>
                          </div>
                        </div>
                        {emailError && 
                        <div id="email_validate">
                          <label id="email-error" className="error" htmlFor="email">
                            <p>Please enter email address.</p>
                          </label>
                        </div>}
                        {isEmailExist && 
                        <div id="email_validate">
                          <label id="email-error" className="error" htmlFor="email">
                            <p>This e-mail ID is already in use. Please log into the app.</p>
                          </label>
                        </div>}
                      </div>
                      <div className="form-group">
                        <div className="input-group mb-2 mr-sm-2">
                          <PhoneInput
                            country={countryCode}
                            value={code}
                            inputProps={{ className: "input-text hide" }}
                            onChange={code => setCode(code)}
                          />
                          <input
                            name="mobile"
                            type="text"
                            className="form-control signup-mobile"
                            id="mobile"
                            placeholder="Mobile Number"
                            role="presentation"
                            minLength="10"
                            maxLength="10"
                            autoComplete="nope"
                            onChange={event =>
                              updateMobileNumber(event.target.value)
                            }
                          />
                        </div>
                        {mobileError && 
                        <div id="mobile_validate">
                          <label id="mobile-error" className="error" htmlFor="mobile">
                            <p>Please provide a valid mobile number.</p>
                          </label>
                        </div>}
                      </div>
                      <div className="form-group">
                        <div className="input-group mb-2 mr-sm-2 signuppassword-group">
                          <input
                            type={`${showPassword?"text":"password"}`}
                            className="form-control signup-password-field"
                            id="password"
                            placeholder="Password"
                            role="presentation"
                            autoComplete="nope"
                            onChange={event =>
                              passCheck("password", event.target.value)
                            }
                            // onBlur={event => checkPassOnBlur}
                            />
                            <img src={showPassword?eyeToggleOff:eyeToggleOn} className="eye-toggle-password" onClick={()=>setShowPassword(!showPassword)}/>
                        </div>
                        {passwordError && 
                        <div id="password_validate">
                          <label id="password-error" className="error" htmlFor="password">
                            <p>Please enter password.</p>
                          </label>
                        </div>}
                        <div className="form-group">
                          <input
                            type="password"
                            className="form-control"
                            id="confirmpassword"
                            placeholder="Confirm Password"
                            role="presentation"
                            // disabled={isPasswordStrong1 == false || isPasswordStrong2 == false}
                            autoComplete="nope"
                            onChange={event =>
                              confirmPassCheck(
                                "confirmpassword",
                                event.target.value
                              )
                            }
                          />

                        {confirmPasswordError && 
                        <div id="password_validate">
                          <label id="password-error" className="error" htmlFor="password">
                            <p>Please enter confirm password.</p>
                          </label>
                        </div>}

                          {passDoneMatch && <p
                            style={{ fontSize: "0.9em", margin: "5px 0" }}
                            id="pass_not_matching"
                            className="text-danger"
                          >
                            <small>
                              <span className="fa fa-exclamation-circle" />
                            </small>
                            &nbsp;
                            Please make sure your passwords match.
                          </p>}
                        </div>
                        <div id="passwordchecks">
                          <p
                            style={{ fontSize: "0.9em", margin: "0 0 9px" }}
                            id="wrong_uplow"
                          >
                            <img src={isPasswordStrong1 ? tickCircle : emptyTickCircle} alt="" />
                            &nbsp;&nbsp; Min. 8 characters, including uppercase and lowercase letters.
                          </p>
                          <p
                            style={{ fontSize: "0.9em", margin: 0 }}
                            id="wrong_numsym"
                          >
                            <img src={isPasswordStrong2 ? tickCircle : emptyTickCircle} alt="" />
                            &nbsp;&nbsp; Include at least one number and one special character.
                          </p>
                        </div>
                      </div>

                      <div className="form-group form-check signup-checkbox-container text-center d-flex align-items-center" onClick={event => setAcceptTnc(!acceptTnc)}>
                        {!acceptTnc && <div className="signup-checkbox" id="agreeterms"/>} 
                        {acceptTnc && <img src={checkbox} id="agreeterms" className="signup-checkbox"/>}
                        <label
                          className="form-check-label text-left"
                          htmlFor="agreeterms"
                          style={{ fontSize: "0.9em" }}
                        >
                          I accept Amaha's&nbsp;
                          <a
                            className="check-box-label"
                            href="https://www.amahahealth.com/terms-and-conditions"
                            target="_blank"
                          >
                            Terms &amp; Conditions
                          </a>
                          &nbsp;and&nbsp;
                          <a
                            className="check-box-label"
                            href="https://www.amahahealth.com/privacy-policy"
                            target="_blank"
                          >
                            Privacy Policy
                          </a>
                        </label>
                      </div>
                      <div className="text-center">
                        <button
                          onClick={createEmployeeHandleFunc}
                          className="sign-up-button"
                          id="createaccount"
                          disabled={isDisabled}
                        >
                          CREATE ACCOUNT
                        </button>
                      </div>
                      <div className="resend-verification-button my-3" onClick={() => setShowResendVerification(true)}>RESEND VERIFICATION E-MAIL</div>
                    </fieldset>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default SignUp

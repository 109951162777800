import * as React from "react"
import { navigate } from 'gatsby'
// import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import SignUp from '../components/Signup';
import Loader from '../components/common/Loader';
import AccountApis from '../core/apis/account';
import { logEvent } from '../core/analytics';
import { useMixpanel } from 'gatsby-plugin-mixpanel';
import Popup from '../components/common/Popup';


function IndexPage() {

  // const [domain, setDomain] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [countryCode, setCountryCode] = React.useState("IN");
  const [countryPhoneCode, setCountryPhoneCode] = React.useState("91");
  const [isExist, setIsExist] = React.useState(false);
  const [isVerified, setIsVerified] = React.useState(null);
  const [successfullyCreated, setSuccessfullyCreated] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [isResentEmail, setIsResendEmail] = React.useState(false);

  const mixpanel = useMixpanel()


  const [domainInfo, setDomainInfo] = React.useState({
    "subdomain": "ih",
    "logo": "https://via.placeholder.com/150",
    "email_extension": "@theinnerhour.com",
    "organisation_name": "AMaha"
  });

  const getDomainInfo = () => {
    AccountApis.domainInfo()
      .then(data => {
        setDomainInfo(data)
      })
      .then(() => {
        setLoading(false);
      })
      .catch(error => {
        setLoading(true);
        console.log(error);
      })
  }

  const resendVerificationEmailHandle = email => {
    const payload = JSON.stringify({"email": email })
    AccountApis.sendReverificationEmail(payload)
    .then(data => {
      setIsResendEmail(true);
    })
    .catch(error => {
      setLoading(false);
      setError(error);
      logEvent("b2b_landing_page_resend_verification_email_error", error, mixpanel)
    })
  }

  const createEmployeeHandle = payload => {
    const data = JSON.stringify(payload);
    setLoading(true);
    AccountApis.createEmployee(data)
    .then(res => {
      setLoading(false);
      return res
    })
    .then((res) => {
      setSuccessfullyCreated(true);
      logEvent("b2b_landing_page_import_employee_success", res, mixpanel)
      return true;
    })
    .catch(error => {
      setLoading(false);
      setError(error);
      logEvent("b2b_landing_page_import_employee_error", error, mixpanel)
    });
  }

  const checkOrgEmail = email => {
    const data = JSON.stringify({ email: email });
    AccountApis.checkEmail(data)
    .then(res => {
      setIsExist(res.exists);
      logEvent("b2b_landing_page_check_email_address", res, mixpanel)
      return res;
    })
    .then(res => {
      setIsVerified(res.verified);
    })
    .catch(error => console.log(error))
  }

  React.useEffect(() => {
    fetch("https://ipapi.co/json/")
      .then(response => response.json())
      .then(data => {
        setCountryCode(data['country_code'])
        return data;
      })
      .then(data => {
        setCountryPhoneCode(data['country_calling_code'].substring(1))
      })
      .catch(error => console.error("Not able to fetch country info"))
  }, [])

  React.useEffect(() => {
    getDomainInfo();
  }, []);


  return (
    <Layout>
      <Seo title="Account Details" />
      {error && <Popup
        title="Error"
        description={error['message']}
        buttonLabel="CLOSE"
        popupType="error"
        showEmailInput={false}
        handleClick={()=> setError(null)}
        togglePopup={()=> setError(null)}
      />}
      <SignUp 
        domainInfo={domainInfo}
        countryCode={countryCode} 
        countryPhoneCode={countryPhoneCode} 
        createEmployeeHandle={createEmployeeHandle}
        isEmailExist={isExist}
        successfullyCreated={successfullyCreated}
        isEmailVerified={isVerified}
        isResentEmail={isResentEmail}
        resendVerificationEmailHandle={resendVerificationEmailHandle}
        checkOrgEmail={checkOrgEmail}
      />
      { loading && <Loader />}

    </Layout>
  )
}

export default IndexPage

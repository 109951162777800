import React from 'react';
import { BounceLoader } from 'react-spinners';

function Loader() {
  return (
      <div className="loader-backdrop">
          <div className="loader">
            <BounceLoader color={"#c6e6eb"} size={60} />
          </div>
      </div>
  );
}

export default Loader;
